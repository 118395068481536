.in-develop-container-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.in-develop-container-div * {
    text-align: center;
    font-size: 14px;
}

.in-develop-component-icon-i {
    font-size: 60px;
    margin-bottom: 15px;
    color: #7cae42;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}