.admin-layout-menu-container-div {
  background-color: #34495e;
  position: fixed;
  left: 0;
  width: 240px;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-layout-menu-container-div-collapse .admin-layout-menu-container-div {
  left: -225px;
}

.admin-layout-menu-collpase-button-div {
  cursor: pointer;
  position: absolute;
  background-color: #34495e;
  right: 0;
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-layout-menu-collpase-button-div:hover {
  background-color: #3b5d7f;
}

.admin-layout-menu-top-div {
  cursor: pointer;
  text-align: right;
  padding: 0 6px;
  min-height: 21px;
  max-height: 21px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-layout-menu-top-div:hover {
  background-color: #3b5d7f;
}

.admin-layout-menu-top-collapse-div {
  color: #ecf0f1;
}

.admin-layout-menu-user-div {
  background-color: #7cae42;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 75px;
  max-height: 75px;
  padding: 6px 0;
  padding-left: 60px;
}

.admin-layout-menu-user-profile-image-div {
  min-width: 60px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  background-color: #fff;
}

.admin-layout-menu-user-default-profile-image-div {
  font-size: 45px;
}

.admin-layout-menu-user-name-div {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 6px;
  min-height: 75px;
  max-height: 75px;
  overflow-y: auto;
}

.admin-layout-admin-menu-list-div {
  background-color: #283038;
  padding: 0 0;
  overflow-y: auto;
}

.admin-layout-admin-menu-link {
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 12px 0;
  padding-left: 45px;
  padding-right: 15px;
  text-decoration: none !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-layout-admin-menu-link * {
  color: #fff;
  font-size: 13px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.admin-layout-admin-menu-link:hover * {
  color: #7cae42 !important;
}

.admin-layout-admin-menu-link.active {
  background-color: #3b5d7f !important;
}

.admin-layout-admin-menu-link.active * {
  color: #fff !important;
}

.admin-layout-admin-menu-link-icon-i {
  position: absolute;
  left: 15px;
  top: 15px;
}

.admin-layout-menu-bottom-div {
  position: fixed;
  bottom: 0;
  width: 240px;
  text-align: center;
  padding: 15px;
  min-height: 90px;
  max-height: 90px;
}

.admin-layout-menu-bottom-logo-div img {
  /* width: 60px; */
  height: 60px;
  /* margin-right: 15px; */
  background-color: #fff;
  border-radius: 15px;
}

.admin-layout-menu-number-of-new-record-div {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 9px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e67e22;
  color: #fff !important;
  border-radius: 50%;
  font-size: 12px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
}

@media (min-width: 992px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (min-width: 576px) {
}

@media (max-width: 575px) {
}
