html {
  scroll-behavior: smooth;
}

.web-user-layout-container-div {
  background-color: #2d3436;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

.web-user-layout-content-div {
  background-color: #fff;
  /* padding: 30px 0; */
  padding-top: 0;
  min-height: 80vh;
  position: relative;
}

.component-container-div {
  /* padding: 45px 0; */
  padding: 21px 0;
}

.filter-bar-container-div {
  padding-right: 75px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

.filter-bar-container-div .top-branch-bar-search-input-div,
.filter-bar-container-div .top-branch-bar-search-input {
  width: 100%;
}

.filter-bar-filter-title-div {
  position: absolute;
  right: 0;
  font-weight: bold;
  color: #7cae42;
  cursor: pointer;
  background-color: #fff;
  z-index: 2;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.filter-bar-filter-title-div:hover,
.filter-bar-filter-title-div:hover i {
  color: #333;
}

.filter-bar-filter-title-div i {
  color: #7cae42;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.filter-bar-filter-number-div {
  position: absolute;
  right: 0px;
  top: -15px;
  z-index: 1;
  background-color: #e67e22;
  color: #fff;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
}

.order-bar-container-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 15px;
}

.order-bar-container-div select {
  border: 1px solid #666;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  height: 35px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.order-bar-container-div select:focus {
  border-color: #7cae42;
}

.filter-container-div {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.filter-container-opened-div {
  max-height: 1000px;
  opacity: 1;
}

.web-user-layout-product-row {
  margin-top: 30px;
}

.web-user-layout-filer-bar-row .product-list-catrgory-tag-div {
  border-top: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 0;
  padding: 21px 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  height: max-content !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.filter-bar-filter-delete-filter-button-div {
  background-color: #fff !important;
  border-color: #e74c3c !important;
  color: #e74c3c !important;
}

.filter-bar-filter-delete-filter-button-div:hover {
  background-color: #e74c3c !important;
  color: #fff !important;
}

.web-user-layout-paging-bar-col {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.page-link {
  color: #7cae42;
  outline: none !important;
  box-shadow: none !important;
}

.page-link:hover {
  color: #333;
}

.page-item.active .page-link {
  background-color: #7cae42;
  border-color: #7cae42;
}

.component-title-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-bottom: 45px; */
  margin-bottom: 21px;
  background-attachment: fixed;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  /*  */
  /* transform: scaleX(0); */
  transform-origin: center center;
}

.component-title-show-div {
  /* transform: scaleX(1); */
}

.component-title-text-div {
  width: 100%;
  font-weight: 700;
  background-color: #fff;
  z-index: 1;
  padding: 0 15px;
  position: relative;
  text-align: center;
  /* mix-blend-mode: screen; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.component-title-text-div > div {
  /* font-family: 'Lobster', cursive; */
  font-family: Tuv;
  font-size: 27px;
  position: relative;
  width: max-content;
  padding: 0px 0;
  color: #538135;
  text-transform: uppercase;
}

.component-title-hr {
  border-top: 2px solid #538135;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.component-container-non-padding {
  padding-left: 0;
  padding-right: 0;
}

.full-hr-div {
  border-bottom: 1px solid #666;
  width: 100%;
}

.fluid-container {
  padding-left: 0;
  padding-right: 0;
}

.web-user-layout-add-product-to-card-modal-img {
  width: 100%;
}

.web-user-layout-add-product-to-card-modal-customer-phone-number-div {
  margin-bottom: 15px;
}

.web-user-layout-add-product-to-card-modal-customer-phone-number-submit-div {
  position: relative;
  display: flex;
  align-items: center;
}

.web-user-layout-add-product-to-card-modal-customer-phone-number-submit-cart-page-link {
  position: absolute;
  right: 0;
  color: #333;
  font-size: 12px;
  text-decoration: underline;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.web-user-layout-add-product-to-card-modal-customer-phone-number-submit-cart-page-link:hover {
  color: #7cae42;
}

.web-user-layout-add-product-to-card-modal-status-div {
  color: #e74c3c;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  margin-top: 9px;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.web-user-layout-scroll-option-div {
  position: fixed;
  flex-direction: column;
  width: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: translateY(300px);
  z-index: 90;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.web-user-layout-scroll-option-div-show {
  transform: translateY(0);
}

.web-user-layout-scroll-option-button-div {
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 9px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

/* .zalo-chat-widget {
  top: 70vh;
} */

/* .zalo-chat-widget {
  position: relative !important;
}

.zalo-chat-widget iframe {
  position: fixed !important;
} */

.web-user-layout-scroll-option-bar {
  overflow: hidden;
  height: 0;
  padding: 0;
  border-top: none;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.web-user-layout-scroll-option-bar > div {
  display: flex;
  align-items: center;
}

.top-branch-bar-hotline-number-first-div {
  font-size: 14px;
}

.web-user-layout-scroll-option-bar > .top-branch-bar-hotline-number-div {
  font-family: Gilroy;
  color: #333 !important;
  font-weight: bold;
}

.web-user-layout-scroll-option-bar .top-branch-bar-hotline-number-a {
  color: #538135 !important;
  font-size: 15px;
}

.web-user-layout-scroll-option-bar .top-branch-hotline-social-facebook-i {
  color: #3b5998 !important;
}

.web-user-layout-scroll-option-bar .top-branch-hotline-social-youtube-i {
  color: #c4302b !important;
}

.web-user-layout-scroll-option-button-i {
  font-size: 39px;
  color: #888;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.web-user-layout-scroll-option-button-div:hover
  .web-user-layout-scroll-option-button-i {
  color: #7cae42;
}

.web-user-layout-scroll-option-button-title {
  position: absolute;
  top: 24px;
  right: 45px;
  width: max-content;
  font-size: 14px;
  font-weight: bold;
}

.web-user-layout-scroll-option-button-group-div > div {
  z-index: 0;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.web-user-layout-scroll-option-button-group-div
  > div:not(.web-user-layout-scroll-option-main-button-div) {
  /* display: none; */
  /* transform: scaleY(0); */
  max-height: 0;
  opacity: 0;
}

.web-user-layout-scroll-option-button-opened-group-div
  > div:not(.web-user-layout-scroll-option-main-button-div) {
  /* display: block; */
  /* transform: scaleY(1); */
  max-height: 1000px;
  opacity: 1;
}

.web-user-layout-scroll-option-main-button-div {
  z-index: 1;
}

.web-user-layout-scroll-option-phone-button-div * {
  color: #2c3e50 !important;
}

.web-user-layout-scroll-option-facebook-button-div * {
  color: #3b5998 !important;
}

.web-user-layout-scroll-option-youtube-button-div * {
  color: #c4302b !important;
}

.web-user-layout-container-div .loading-component-container-div {
  position: relative;
  height: 100vh;
  justify-content: flex-start;
  padding-top: 120px;
}

.read-more-container-div {
  background-color: #fff;
  position: relative;
  /* display: flex;
  align-items: flex-start;
  justify-content: center; */
  overflow: hidden;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.read-more-container-div * {
  /* font-size: 16px;
  line-height: 30px; */
}

.read-more-container-div > div {
  width: 100%;
}

.read-more-button-div {
  position: absolute;
  background-image: linear-gradient(transparent, #fff, #fff, #fff);
  cursor: pointer;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-size: 21px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.read-more-button-div:hover {
  color: #7cae42;
}

.read-more-button-div:hover > i {
  color: #7cae42;
}

.top-branch-bar-hotline-number-div img {
  height: 30px;
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
  .component-title-text-div > div {
    font-size: 24px;
  }

  .web-user-layout-scroll-option-bar {
    height: 45px;
    padding: 3px;
    border-top: 1px solid #7cae42;
  }
}

@media (max-width: 767px) {
  /* .component-title-text-div > div {
    font-size: 24px;
  } */

  .read-more-container-div > div video,
  .read-more-container-div > div img {
    max-width: 100%;
    height: auto;
  }

  .read-more-container-div > div iframe,
  .read-more-container-div > div figure {
    max-width: 100%;
  }

  .web-user-layout-add-product-to-card-modal-img {
    margin-bottom: 30px;
  }

  .web-user-layout-scroll-option-bar .top-branch-hotline-social-i {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 575px) {
}
