/* .header-container-div {
    border-bottom: none;
} */

.service-container {
    background-color: #fff;
}

.product-container, .product-container .component-title-text-div {
    /* background-color: #f7f7f7; */
    background-color: #fff;
}

.policy-container {
    /* background-color: #273c75; */
    background-color: #fff;
}

/* .policy-container .component-title-text-div {
    color: #fbc531;
} */

/* .typical-face-container, .typical-face-container .component-title-text-div {
    background-color: #f4ffe7;
} */

.video-container, .video-container .component-title-text-div {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
    /*  */
    background-color: #fff;
}

.video-show-container, .video-show-container .component-title-text-div {
    background-color: #000;
}

.video-show-container .component-title-text-div > div {
    color: #7cae42;
}

.video-show-container .component-title-hr {
    border-color: #7cae42;
}

.event-container {
    background-color: #fff;
}

@media (max-width: 1199px) {

}

@media (max-width: 991px) {
    .header-container-div {
        /* border-bottom: 1px solid #ccc; */
    }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {

}