.product-list-row-div > div:last-child {
  padding-top: 30px;
}

.product-list-container-div {
  background-color: #fff;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  /* border: 1px solid #ccc;
    border-radius: 3px; */
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  /*  */
  /* transform: scaleY(0);
    transform-origin: center center;
    opacity: 0; */
}

.product-list-container-show-div {
  /* transform: scaleY(1);
    opacity: 1; */
}

.product-list-carousel-item-outer-div {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
  /*  */
  /* transform: scaleX(0);
    transform-origin: left center;
    opacity: 0; */
}

.product-list-container-show-div .product-list-carousel-item-outer-div {
  /* transform: scaleX(1);
    opacity: 1; */
}

.product-list-container-div {
  margin-bottom: 45px;
}

.product-list-container-div:not(:last-child) {
  margin-bottom: 30px;
}

.product-list-row-div {
  margin-left: 0;
  margin-right: 0;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

.product-list-catrgory-background-div {
  height: 100%;
  min-height: 450px;
  box-shadow: 0px 0px 9px 0 #000;
  background-size: cover;
  background-position: center center;
}

/* .product-list-catrgory-background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.product-list-catrgory-title-div {
  margin-bottom: 15px;
  text-align: center;
}

.product-list-catrgory-title-div > a {
  /* font-family: 'Lobster', cursive; */
  font-family: Gilroy;
  margin: 9px 0;
  font-size: 21px;
  font-weight: bold;
  padding: 0;
  color: #333;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-catrgory-title-div > a:hover {
  text-decoration: none;
  color: #7cae42;
}

.product-list-catrgory-title-link {
  text-decoration: none !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-catrgory-title-link,
.product-list-catrgory-title-link * {
  color: #ccc;
  font-size: 12px;
}

.product-list-catrgory-title-link:hover,
.product-list-catrgory-title-link:hover * {
  color: #7cae42;
}

.product-list-catrgory-tag-div {
  margin: 6px 0 0;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
}

.product-list-catrgory-tag-item-div {
  display: inline-block;
  cursor: pointer;
  font-family: Gilroy;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 0 12px 6px 0;
  padding: 3px 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-catrgory-tag-item-div:hover {
  border-color: #7cae42;
  color: #7cae42;
}

.product-list-catrgory-tag-selected-item-div {
  background-color: #7cae42 !important;
  border-color: #7cae42 !important;
  color: #fff !important;
}

.product-list-carousel-div {
  margin: 15px -15px;
  /* margin: 15px 0; */
  /* padding: 0 30px; */
}

/* .product-list-carousel-item-col {
    padding: 15px 9px;
} */

.product-list-carousel-item-link {
  text-decoration: none !important;
}

.product-list-carousel-item-div {
  text-align: left;
  background-color: #fff;
  border: 1px solid #ced6e0;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  padding-bottom: 15px;
  /* padding: 9px 21px;
    padding-top: 30px; */
  padding: 6px;
  /* padding-bottom: 45px; */
  cursor: pointer;
  transform: scale(0);
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-carousel-item-col .product-list-carousel-item-div  {
  margin-bottom: 30px;
}

.product-list-carousel-sale-item-div {
  position: absolute;
  top: 3px;
  left: 3px;
}

.product-list-carousel-sale-item-div > i {
  font-size: 30px;
  color: #f39c12;
}

.carousel-item.active .product-list-carousel-item-div {
  transform: scale(1);
}

.product-list-carousel-item-status-div {
  position: absolute;
  top: 9px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.product-list-carousel-item-status-div > div {
  margin-left: 6px;
  display: flex;
  width: 21px;
  height: 21px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  /* padding: 6px 21px; */
  border-radius: 50%;
  font-family: Gilroy !important;
  color: #fff;
}

.product-list-carousel-item-status-div > div > i {
  color: #fff;
  font-size: 12px;
}

.product-list-carousel-item-status-hot-div {
  background-color: #e74c3c;
}

.product-list-carousel-item-status-new-div {
  background-color: #27ae60;
}

.product-list-carousel-item-div:hover {
  border: 1px solid #7cae42;
  /* box-shadow: 0px 0px 9px 0 #7cae42; */
}

.product-list-carousel-item-img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.product-list-carousel-title-div {
  margin-top: 30px;
  font-family: Gilroy;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  text-decoration: none !important;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #538135;
}

.product-list-carousel-price-div {
  margin-top: 15px;
  font-family: sans-serif;
}

.product-list-carousel-price-div > span:first-child {
  font-family: Gilroy;
  font-size: 24px;
  /* font-weight: bold; */
  /* color: #7cae42; */
  color: #000;
  text-decoration: none !important;
  position: relative;
}

.product-list-carousel-price-div > span:nth-child(3) {
  text-decoration: line-through;
  color: #ccc;
}

.product-list-carousel-discount-percent-span {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: bold;
  color: #d35400;
  font-style: italic;
}

.product-list-carousel-promo-text-div {
  font-family: Gilroy;
  font-size: 13px;
  /* color: #74b9ff; */
  color: #d35400;
  font-weight: bold;
  text-decoration: none !important;
  padding-bottom: 15px;
}

.product-list-carousel-disable-price-div {
  font-family: Gilroy;
  font-size: 15px;
  color: #ccc;
  font-family: sans-serif;
  text-decoration: line-through;
}

.product-list-carousel-cart-div {
  /* position: absolute;
    left: 0;
    bottom: 0; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* height: 30px; */
  /* background-color: #ecf0f1; */
  width: 100%;
  padding: 3px 0 9px;
  /* padding-top: 15px; */
  cursor: pointer;
  /* transform: translateY(100%); */
  transform-origin: left top;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

/* .product-list-carousel-item-div:hover .product-list-carousel-cart-div {
    transform: translateY(0);
} */

.product-list-carousel-item-status-is-flash-sale-div,
.product-list-carousel-item-status-is-new-div,
.product-list-carousel-item-status-is-hot-div,
.product-list-carousel-item-status-temp-div {
  font-family: Gilroy;
  font-size: 12px;
  /* font-weight: bold; */
  padding: 1px 15px;
  border-radius: 3px;
  margin-right: 12px;
  border: 1px solid #fff;
  display: none;
}

.product-list-carousel-item-status-is-flash-sale-div {
  border-color: #f39c12;
  background-color: #f39c12;
  color: #fff;
}

.product-list-carousel-item-status-is-new-div {
  border-color: #27ae60;
  background-color: #27ae60;
  color: #fff;
}

.product-list-carousel-item-status-is-hot-div {
  border-color: #e74c3c;
  background-color: #e74c3c;
  color: #fff;
}

.product-list-carousel-item-status-temp-div {
  opacity: 0;
  display: block;
}

.product-list-carousel-item-status-show-div {
  display: block;
}

.product-list-carousel-cart-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #666;
}

.product-list-carousel-cart-button-div:hover {
  background-color: #7cae42;
  border-color: #7cae42;
}

.product-list-carousel-cart-icon-i {
  font-size: 15px;
  color: #666;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-carousel-cart-button-div:hover
  .product-list-carousel-cart-icon-i {
  color: #fff;
}

.product-list-container-div .carousel-control-prev,
.product-list-container-div .carousel-control-next {
  padding: 0;
  width: 30px;
  justify-content: center !important;
  opacity: 1;
  background-color: transparent;
}

.product-category-playback-icon {
  color: #666;
  font-size: 18px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.carousel-control-prev:hover .product-category-playback-icon,
.carousel-control-next:hover .product-category-playback-icon {
  color: #7cae42;
}

.product-list-container-div .carousel-control-prev-icon,
.product-list-container-div .carousel-control-next-icon {
  background-color: #ccc;
  background-image: none;
  width: 15px !important;
  height: 15px;
  border-radius: 50%;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-container-div .carousel {
  padding-bottom: 30px;
}

.product-list-container-div
  .carousel-control-prev:hover
  .carousel-control-prev-icon,
.product-list-container-div
  .carousel-control-next:hover
  .carousel-control-next-icon {
  background-color: #7cae42;
}

/* .product-list-container-div .carousel-control-prev, .product-list-container-div .carousel-control-next {
    display: none;
} */

.product-list-container-div .carousel-indicators {
  margin-bottom: 15px;
  /* background-color: #000; */
}

.product-list-container-div .carousel-indicators li {
  border: 1px solid #333 !important;
  border-radius: 15px;
  width: 9px !important;
  height: 9px !important;
  margin: 0 9px !important;
  background-color: #fff !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-container-div .carousel-indicators li:hover {
  border-color: #7cae42 !important;
}

.product-list-container-div .carousel-indicators li.active {
  width: 30px !important;
  background-color: #7cae42 !important;
  border-color: #7cae42 !important;
}

.product-list-no-have-product-div {
  font-size: 12px;
  margin-top: 30px;
  font-style: italic;
  color: #333;
  text-align: center;
}

.product-item-content-div {
  position: relative;
}

.product-item-cart-div {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  /* display: none !important; */
}

.product-list-carousel-item-div:hover .product-item-cart-div {
  /* opacity: 1; */
}

.product-item-cart-icon-div {
  color: #7cae42;
  border: 1px solid #7cae42;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 auto;
  margin-bottom: 9px;
}

.product-item-cart-text-div {
  color: #7cae42;
  font-size: 10px;
  text-align: center;
}

.product-item-cart-item-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* padding: 6px 0; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 90px;
  border: 1px solid #fff;
  border-radius: 3px;
  padding-bottom: 15px;
  font-family: Gilroy;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-item-cart-item-div * {
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-item-cart-item-div:hover > .product-item-cart-icon-div {
  border-color: #333;
}

.product-item-cart-buy-item-div:hover > .product-item-cart-icon-div {
  background-color: #333;
}

.product-item-cart-item-div:hover > .product-item-cart-text-div {
  color: #333;
}

.product-item-cart-item-div:hover .fa-eye {
  color: #333;
}

.product-item-cart-buy-item-div > .product-item-cart-icon-div {
  background-color: #7cae42;
  color: #fff;
}

.product-list-catrgory-background-img,
.product-list-carousel-title-div,
.product-list-carousel-price-div .product-list-carousel-promo-text-div,
.product-list-carousel-item-img,
.product-list-carousel-item-div {
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-list-tab-container-div > .nav-tabs {
  border-bottom: none;
  justify-content: center;
}

.product-list-tab-container-div .nav-tabs .nav-link,
.product-list-tab-container-div .nav-tabs .nav-link * {
  font-family: optima;
  font-weight: bold;
  font-size: 15px;
  color: #538135 !important;
  padding-left: 0;
  padding-right: 0;
}

.product-list-tab-container-div .nav-tabs .nav-link.active,
.product-list-tab-container-div .nav-tabs .nav-link.active * {
  color: #538135 !important;
}

.product-list-tab-container-div .nav-tabs .nav-link:nth-child(2),
.product-list-tab-container-div .nav-tabs .nav-link:nth-child(2) * {
  color: #e74c3c !important;
}

.product-list-tab-container-div .nav-tabs .nav-link:nth-child(2) img {
  width: 18px;
}

.product-list-tab-container-div .nav-tabs .nav-link:nth-child(2).active {
  border-color: #e74c3c !important;
}

.product-list-tab-item-wrapper-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-list-tab-item-hot-icon {
  position: absolute;
  width: 42px !important;
  top: -36px;
  /* right: -15px; */
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) {
  .product-list-carousel-item-img {
    height: 150px;
  }
}

@media (min-width: 992px) {
  .product-list-catrgory-title-div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
  }

  .product-list-catrgory-title-link {
    position: absolute;
    right: 30px;
    bottom: 0;
  }

  .product-item-cart-item-div {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 0;
  }

  .product-item-cart-text-div {
    font-size: 13px;
  }

  .product-list-tab-container-div .nav-tabs .nav-link,
  .product-list-tab-container-div .nav-tabs .nav-link * {
    width: 270px;
  }
}

@media (max-width: 991px) {
  .product-list-carousel-item-div {
    padding: 6px;
    /* padding-top: 45px; */
  }

  .product-list-catrgory-title-link {
    display: block;
    margin-top: 9px;
  }

  .product-list-tab-container-div .nav-tabs .nav-link {
    padding-left: 12px;
    padding-right: 12px;
    width: 162px;
  }
}

@media (min-width: 768px) {
  .product-item-cart-item-div {
    margin: 0 6px;
  }

  .product-list-tab-container-div .nav-tabs .nav-link,
  .product-list-tab-container-div .nav-tabs .nav-link * {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .product-list-carousel-item-img {
    height: 210px;
  }

  .product-list-carousel-title-div {
    font-size: 15px;
  }

  .product-list-tab-container-div .nav-tabs .nav-link:nth-child(2) img {
    width: 15px;
  }

  /* .product-list-catrgory-title-div {
        text-align: center;
    } */

  .product-list-catrgory-background-div {
    box-shadow: none;
    margin-left: -15px;
    margin-right: -15px;
  }

  .product-list-row-div > div:last-child {
    /* padding-top: 60px;
        padding-bottom: 45px; */
    padding-top: 27px;
    padding-bottom: 15px;
  }

  .product-list-carousel-item-col:nth-child(odd) {
    padding-right: 9px;
  }

  .product-list-carousel-item-col:nth-child(even) {
    padding-left: 9px;
  }

  .product-list-tab-container-div .nav-tabs .nav-link {
    padding-left: 9px;
    padding-right: 9px;
    width: 162px;
  }
}

@media (max-width: 575px) {
  .product-list-carousel-item-img {
    height: 135px;
  }

  /* .product-list-catrgory-background-img {
        height: 360px;
    } */
}

@media (min-width: 992px) {
  .product-item-cart-item-div {
    margin: 0 0;
  }
}

@media (min-width: 1200px) {
  .product-item-cart-item-div {
    margin: 0 6px;
  }
}
