.image-slider-container-div {
    /* background: #2d3436; */
    width: 100%;
    /* overflow: hidden; */
}

.image-slider-carousel-div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.image-slider-caroudel-playback-icon {
    color: #95a5a6;
    font-size: 18px;
}

.image-slider-carousel-video {
    width: 100%;
    height: 100%;
}

.image-slider-carousel-video > div {
    padding: 0;
}

.image-slider-carousel-video > div, .image-slider-carousel-video > div > div {
    height: 100% !important;
}

.image-slider-carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

.carousel-item.active .carousel-caption {
    opacity: 1;
}

.carousel-control-prev, .carousel-control-next {
    padding: 12px 18px;
}

.carousel-control-prev {
    justify-content: flex-start !important;
    width: 45px;
    height: 45px;
    top: inherit;
    bottom: inherit;
}

.carousel-control-next {
    justify-content: flex-end !important;
    width: 45px;
    height: 45px;
    top: inherit;
    bottom: inherit;
}

.carousel-control-prev-icon, .carousel-control-next-icon  {
    width: 12px !important;
}

.carousel-caption {
    padding: 15px 15px 45px 15px !important;
    border-radius: 3px;
    bottom: 0 !important;
    opacity: 0;
    left: 0 !important;
    right: 0 !important;
    font-size: 12px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.carousel-caption, .carousel-caption * {
    color: #fff !important;
}

.carousel-indicators {
    margin-bottom: 9px;
    /* bottom: -9px; */
}

.carousel-indicators li {
    width: 6px !important;
    height: 6px !important;
    border: 1px solid #95a5a6 !important;
    border-radius: 50%;
    background-color: #fff !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.carousel-indicators li.active {
    border-color: #7cae42 !important;
    background-color: #7cae42 !important;
}

.carousel-control-next:focus > i, .carousel-control-prev:focus > i {

}

.carousel-control-next:hover > i, .carousel-control-prev:hover > i {
    color: #7cae42;
}

.image-slider-image-navigation-container-div {
    padding-bottom: 120px;
    position: relative;
}

.image-slider-image-navigation-container-div .carousel-indicators {
    display: none;
}

.image-slider-image-navigation-div {
    /* background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center; */
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 105px;
    overflow-y: hidden;
    overflow-x: auto;
}

.image-slider-image-navigation-div > div {
    width: max-content;
}

.image-slider-image-navigation-item-div {
    /* height: 90px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; */
    height: 90px;
    width: 90px;
    /* margin: 0 3px; */
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.image-slider-image-navigation-item-div:not(.image-slider-image-navigation-active-item-div):hover {
    opacity: 0.7;
}

.image-slider-image-navigation-active-item-div {
    opacity: 1;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}