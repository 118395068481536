.cart-page-cart-list-element-wrapper-div {
  padding-bottom: 150px;
}

.cart-page-title {
  font-size: 21px;
  margin: 30px 0 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid #999;
}

.cart-page-product-item-wrapper-div {
  position: relative;
  display: flex;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.cart-page-product-item-img {
  width: 100%;
}

.cart-page-product-item-link {
  color: initial !important;
  text-decoration: underline;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.cart-page-product-item-link:hover {
  color: #7cae42 !important;
}

.cart-page-product-item-info-wrapper-div {
  padding-bottom: 30px;
  position: relative;
}

.cart-page-product-item-content-info-div {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  padding-right: 81px;
}

.cart-page-product-item-content-price-div {
  font-weight: bold;
  font-size: 18px;
  color: #666;
}

.cart-page-product-item-content-info-left-side-div {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.cart-page-product-item-content-info-bottom-side-div {
  position: absolute;
  left: 15px;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.cart-page-product-item-content-info-delete-button-div {
  cursor: pointer;
  text-decoration: underline;
  color: #666;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.cart-page-product-item-content-info-delete-button-div:hover {
  color: #7cae42;
}

.cart-page-product-item-content-info-sale-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.cart-page-product-item-content-info-sale-sale11-span {
  color: #d35400;
  font-weight: bold;
}

.cart-page-product-item-content-info-sale-original-price-span {
  color: #bdc3c7;
  font-size: 15px;
  text-decoration: line-through;
}

.cart-page-product-item-content-info-sale-column-span {
  margin: 0 9px;
  color: #999;
}

.cart-page-product-item-content-info-sale-discount-percent-span {
  font-weight: bold;
  color: #d35400;
  font-style: italic;
}

.cart-page-submit-form-wrapper-div {
  border: 1px solid #7cae42;
  border-radius: 6px;
  padding: 15px 15px 30px;
}

.cart-page-submit-form-title-div {
  color: #7cae42;
  font-weight: bold;
  font-family: Tuv;
  text-transform: capitalize;
  font-size: 18px;
}

.cart-page-total-wrapper-div {
  margin-top: -15px;
  margin-bottom: 30px;
}

.cart-page-total-div {
  font-size: 30px;
  font-weight: bold;
}

.cart-page-product-item-content-name-div {
  font-weight: 700;
  font-size: 18px;
}

.cart-page-voucher-wrapper-div {
  /* background-color: pink; */
  margin: 0 0 30px;
}

.cart-page-voucher-show-hide-input-button-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cart-page-voucher-show-hide-input-button-span {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.cart-page-voucher-show-hide-input-button-span:hover {
  color: #7cae42 !important;
}

.cart-page-voucher-show-hide-input-wrapper-div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  margin-top: 12px;
  margin-bottom: 3px;
}

.cart-page-voucher-show-hide-input-wrapper-div .form-components-button {
  position: absolute;
  right: 0;
  margin: 0;
  overflow: hidden;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cart-page-voucher-show-hide-input-wrapper-div .form-components-text-input {
  padding-right: 117px;
}

.cart-page-button-wrapper-div .form-components-button {
  background-color: #7cae42;
  color: #fff;
}

.cart-page-button-wrapper-div .form-components-button:hover {
  background-color: #fff;
  color: #7cae42;
}

.cart-page-button-wrapper-div .form-components-button i {
  color: #fff;
}

.cart-page-button-wrapper-div .form-components-button:hover i {
  color: #7cae42;
}

.cart-page-button-wrapper-div .form-components-text-input-component {
  width: 100%;
}

.cart-page-voucher-info-div {
  background-color: #7cae42;
  margin-top: 0px;
  color: #fff;
  padding: 6px 15px;
  border-radius: 3px;
  font-size: 18px;
  text-align: center;
}

.cart-page-voucher-info-invaid-div {
  background-color: #d35400;
}

.cart-page-voucher-new-total-div {
  color: #95a5a6;
  margin-top: 12px;
  margin-bottom: -6px;
}

@media (min-width: 1200px) {}

@media (max-width: 1199px) {}

@media (min-width: 992px) {}

@media (max-width: 991px) {}

@media (min-width: 768px) {}

@media (max-width: 767px) {}

@media (min-width: 576px) {}

@media (max-width: 575px) {}