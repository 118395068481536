.video-list-container {
  padding-bottom: 45px;
}

.video-list-item-div {
  height: 300px;
  /* padding: 6px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #dfe6e9; */
  border-radius: 3px;
}

.video-list-item-div > * {
  width: 100% !important;
  height: 100% !important;
}

.video-list-item-div > * > * {
  height: 100% !important;
}

.video-list-link-to-youtube-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.video-list-link-to-youtube-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  padding: 6px 15px;
  background-color: #c4302b;
  border: 1px solid #c4302b;
  border-radius: 6px;
  text-decoration: none !important;
  color: #fff;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.video-list-link-to-youtube-link:hover {
  border-color: transparent;
  background-color: #fff;
  color: #c4302b;
}

.video-list-link-to-youtube-link * {
  color: #fff;
  font-size: 30px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.video-list-link-to-youtube-link:hover * {
  color: #c4302b;
}

.video-list-link-to-youtube-i {
  margin-right: 6px;
}

.component-container-div .form-read-more-button-div {
  color: #7cae42 !important;
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
  .video-list-item-div {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
}
