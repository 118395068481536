.loading-component-container-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading-component-container-div > i {
    font-size: 60px;
    margin-bottom: 15px;
    color: #7cae42;
}

.loading-component-container-div > div {
    font-size: 14px;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}