.color-tags-slider-wrapper-div .carousel-control-prev, .color-tags-slider-wrapper-div .carousel-control-next {
    background-color: #fff;
    padding: initial;
    top: 9px;
    width: 12px;
    height: 45px;
    display: flex;
    align-items: center;
}

.color-tags-slider-playback-icon {
    color: #666;
    font-size: 18px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

.carousel-control-prev:hover .color-tags-slider-playback-icon, .carousel-control-next:hover .color-tags-slider-playback-icon {
    color: #7cae42;
} 

.color-tags-slider-item-group-div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.color-tags-slider-item-group-item-div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 0;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 0 12px;
    z-index: 2;
    border-top: 3px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.color-tags-slider-item-group-item-div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.color-tags-slider-item-group-item-selected-div {
    border-top-color: #7f8fa6;
    /* padding-top: 12px;
    padding-bottom: 0; */
}

.color-tags-slider-item-group-item-color-div {
    width: 30px;
    height: 30px;
    border: 1px solid #dff9fb;
    border-radius: 50%;
    margin-bottom: 12px;
}

.color-tags-slider-item-group-item-name-div {
    min-height: 36px;
    max-height: 36px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}

@media (min-width: 992px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) {
    
}

@media (max-width: 767px) {
    
}

@media (min-width: 576px) {

}

@media (max-width: 575px) {

}