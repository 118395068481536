.admin-sale-request-content-modal-content-div .container {
  padding-left: 0;
  padding-right: 0;
}

.admin-sale-request-content-modal-content-div .cart-page-title {
  margin-top: 0;
  margin-bottom: 12px;
}

.admin-sale-request-content-modal-content-customer-info-phone-div {
  font-weight: 700;
  font-size: 18px;
}

.admin-sale-request-new-record-wrapper-div {
  position: relative;
  font-weight: 700;
  display: flex;
  padding-left: 30px;
}

.admin-sale-request-new-record-sign-div {
  width: 15px;
  height: 15px;
  background-color: #e67e22;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
}

.admin-cart-item-x-span {
  margin: 0 12px;
  margin-right: 0;
  text-decoration: none;
}

.admin-cart-item-amount-span {
  font-weight: 700;
  margin-left: 3px;
}

.admin-order-list-amount-wrapper-div {
  align-items: center;
}

.admin-order-list-amount-span {
  font-weight: 700;
  font-size: 30px;
}

.admin-sale-request-voucher-div {
  font-weight: normal;
  margin-top: 9px;
  font-size: 14px;
  color: #d35400;
  text-decoration: none;
}

.cart-page-total-voucher-div {
  color: #d35400;
  margin-top: 15px;
  font-weight: 700;
}

@media (min-width: 1200px) {}

@media (max-width: 1199px) {}

@media (min-width: 992px) {}

@media (max-width: 991px) {}

@media (min-width: 768px) {}

@media (max-width: 767px) {}

@media (min-width: 576px) {}

@media (max-width: 575px) {}