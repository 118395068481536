.product-detail-container-div {
  padding-bottom: 30px;
  min-height: 100vh;
}

.product-detail-cotent-col-div {
  padding-top: 30px;
}

.product-detail-product-cotent-col-div {
  margin-top: 30px;
  /* padding-top: 45px; */
  /* border-top: 1px solid #333; */
}

.product-detail-product-cotent-col-div .sun-editor-editable {
  padding: 0;
}

.product-detail-product-name-h1 {
  /* font-size: 30px;
    color: #44403e;
    margin-bottom: 10px;
    font-family: "Old Standard TT",serif;
    letter-spacing: .01em; */
  color: #333;
  font-family: Tuv;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.6;
  color: #538135;
}

.product-detail-product-status-div {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.product-detail-product-status-div>div {
  padding: 3px 15px;
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 3px;
  color: #fff;
}

.product-detail-product-sale11-div {
  background-color: #f39c12;
}

.product-detail-product-isnew-div {
  background-color: #27ae60;
}

.product-detail-product-ishot-div {
  background-color: #e74c3c;
}

.product-detail-product-description-div {
  /* font-size: 16px; */
  margin: 45px 0px;
  margin-bottom: 60px;
  /* line-height: 30px;
    font-family: Gilroy; */
}

.product-detail-product-price-div {
  /* font-size: 27px;
  font-weight: bold;
  color: #7cae42;
  font-family: sans-serif;
  text-decoration: none !important; */
  font-family: Gilroy;
  font-size: 24px;
  color: #000;
  text-decoration: none !important;
  position: relative;
}

.product-detail-product-original-price-div {
  position: relative;
}

.product-detail-product-original-price-div>span:first-child {
  text-decoration: line-through;
  color: #ccc;
  font-size: 16px;
}

.product-detail-product-discount-percent-span {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: bold;
  color: #d35400;
  font-style: italic;
}

.product-detail-product-promotext-div {
  font-family: Gilroy;
  font-size: 13px;
  color: #d35400;
  font-weight: bold;
  text-decoration: none !important;
}

.product-detail-button-div {
  margin: 30px 0;
}

.product-detail-color-tags-wrapper-div {
  margin: 9px 0;
}

.product-detail-color-tags-wrapper-div>div {
  margin-top: 15px;
  margin-bottom: 12px;
}

.product-detail-color-tags-item-div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 9px solid #fff;
  display: inline-block;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0px 0px 1px 0 #000;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.product-detail-color-tags-item-div:not(:last-child) {
  margin-right: 9px;
}

.product-detail-color-tags-item-div:hover {
  opacity: 1;
}

.product-detail-color-tags-item-selected-div {
  width: 30px;
  height: 30px;
  opacity: 1;
  border: 1px solid #bdc3c7;
  box-shadow: 0px 0px 1px 0 #fff;
}

.product-detail-product-cotent-col-div .tabs-container-div>.nav-tabs .nav-link {
  max-width: 25%;
  min-width: 25%;
}

.product-detail-sale-contact-wrapper-div {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 45px !important;
}

.product-detail-sale-contact-wrapper-div * {
  font-family: Gilroy;
}

.product-detail-sale-contact-wrapper-div>div {
  width: 43%;
}

.product-detail-sale-contact-title-div {
  /* font-weight: bold; */
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px !important;
}

.product-detail-sale-contact-contact-div {
  text-align: right;
}

.product-detail-sale-contact-line-div {
  width: 14% !important;
  height: 231px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
}

.product-detail-sale-contact-line-div>div:first-child {
  position: absolute;
  height: 100%;
  border-left: 1px solid #7cae42;
}

.product-detail-sale-contact-line-div>div:last-child {
  background-color: #fff;
  padding: 9px;
  display: block;
  z-index: 1;
  font-size: 15px;
}

.product-detail-sale-contact-form-div {}

.product-detail-sale-contact-item-div {
  margin-top: 24px !important;
}

.product-detail-sale-contact-item-div a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #666;
  padding: 6px 15px;
  border-radius: 21px;
  font-weight: bold;
  color: #666 !important;
  text-decoration: none !important;
  font-size: 15px;
  height: 45px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-detail-sale-contact-item-div a>img {
  margin-right: 15px;
  height: 30px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.product-detail-sale-contact-item-div:hover a>img {
  height: 0;
  margin-right: 0;
}

.product-detail-sale-contact-phone-div:hover a {
  color: #fff !important;
  background-color: #7cae42 !important;
  border-color: #7cae42 !important;
}

.product-detail-sale-contact-zalo-div:hover a {
  color: #fff !important;
  background-color: #017cc1 !important;
  border-color: #017cc1 !important;
}

.product-detail-sale-contact-messenger-div:hover a {
  color: #fff !important;
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}

.product-detail-product-price-div {
  font-size: 30px;
}

.product-detail-product-original-price-div>span:first-child {
  font-size: 21px;
}

.product-detail-product-discount-percent-span {
  font-size: 24px;
}

.product-detail-product-promotext-div {
  font-size: 16px;
}

.product-detail-product-detail-order-buttons-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.product-detail-product-detail-order-buttons-container-div>div:nth-child(2) {
  margin: 0 12px;
}

.product-detail-product-detail-order-button-div {
  border: 1px solid #551f7a;
  border-radius: 6px;
  padding: 18px 30px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #551f7a !important;
  text-decoration: none !important;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  text-transform: uppercase;
  position: relative;
}

.product-detail-product-detail-order-button-sub-div {
  position: absolute;
  font-size: 12px;
  font-weight: normal;
  bottom: 6px;
}

.product-detail-product-detail-order-button-div {
  color: #f5f6fa !important;
  font-weight: 700;
  font-size: 27px;
}

.product-detail-product-detail-order-button-div:first-child {
  background-color: #27ae60;
  border-color: #27ae60;
}

.product-detail-product-detail-order-button-div:first-child:hover {
  background-color: #fff !important;
  color: #27ae60 !important;
}

.product-detail-product-detail-order-button-div:last-child {
  background-color: #e74c3c;
  border-color: #e74c3c;
}

.product-detail-product-detail-order-button-div:last-child:hover {
  background-color: #fff !important;
  color: #e74c3c !important;
}

/* .product-detail-product-detail-order-button-div:hover {
  color: #f5f6fa !important;
  background-color: #551f7a;
} */

@media (min-width: 1200px) {}

@media (max-width: 1199px) {}

@media (min-width: 992px) {}

@media (max-width: 991px) {
  .product-detail-product-cotent-col-div .tabs-container-div>.nav-tabs .nav-link {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 12px;
  }

  /* .product-detail-product-cotent-col-div
    .tabs-container-div
    > .nav-tabs
    .nav-link {
    width: 28%;
  }

  .product-detail-product-cotent-col-div
    .tabs-container-div
    > .nav-tabs
    .nav-link:last-child {
    width: 16%;
  } */

  .product-detail-sale-contact-wrapper-div>div {
    width: 40%;
  }

  .product-detail-sale-contact-line-div {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .product-detail-product-price-div {
    font-size: 45px;
  }

  .product-detail-product-original-price-div>span:first-child {
    font-size: 36px;
  }

  .product-detail-product-discount-percent-span {
    font-size: 39px;
  }

  .product-detail-product-promotext-div {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .product-detail-product-detail-order-buttons-container-div {
    flex-direction: column;
  }

  .product-detail-product-detail-order-buttons-container-div>div:nth-child(2) {
    margin: 15px 12px;
  }

  .product-detail-product-detail-order-button-div {
    width: 100%;
  }

  .product-detail-product-description-div {
    /* text-align: justify; */
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .product-detail-product-cotent-col-div {
    margin-top: 0;
  }

  /* .product-detail-product-cotent-col-div
    .tabs-container-div
    > .nav-tabs
    .nav-link {
    width: 25% !important;
  } */

  .product-detail-sale-contact-title-div {
    margin-bottom: 0 !important;
  }

  .product-detail-sale-contact-wrapper-div {
    flex-direction: column;
  }

  .product-detail-sale-contact-wrapper-div>div {
    width: 100%;
  }

  .product-detail-sale-contact-line-div {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    height: 45px;
    width: 100% !important;
  }

  .product-detail-sale-contact-line-div>div:first-child {
    height: initial;
    width: 100%;
    border: none;
    border-top: 1px solid #7cae42 !important;
  }
}

@media (min-width: 576px) {}

@media (max-width: 575px) {}